<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Jobs"
              url="/pages/masterlists/jobs/manage"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-update-cancel="(v) => loadJobs()"
              @on-row-click="(v) => onViewDetail(v)"
            >
              <template #columns>
                <DxColumn data-field="code" caption="Code" />
                <DxColumn data-field="name" caption="Name" />
                <DxColumn data-field="job_type" caption="Type" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobApi from '@api/jobs';
import { queryParameters } from '@/schema';

export default {
  name: 'JobsPage',
  components: {},
  data: () => ({
    dataSource: [],
  }),
  mounted() {
    this.loadJobs();
  },
  methods: {
    loadJobs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      jobApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onViewDetail(event) {
      const data = event.data;

      if (data) {
        const url = `/pages/masterlists/jobs/view?id=${data.id}`;
        this.$router.push(url);
      }
    },
    onDelete(event) {
      jobApi
        .delete(event)
        .then(({ data }) => {
          this.loadJobs();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
